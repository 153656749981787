import axios from 'axios'
import commovisconfig from '@/services/config'

//const state = {
//  tenantinfo: {
//    tenantname: null,
//    title: null,
//    details: {
//      Id: null,
//      CId: null
//    },
//    description: null,
//    id: null,
//    redirect: false,
//    primarycolor: null,
//    secondarycolor: null,
//    baseAPI: null,
//    fullURL: null,
//    tenantLogoBase64: null,
//    logo: null,
//    paymentOption: null,
//    userRoleCost: {
//      Participant: null,
//      Facilitator: null,
//      TenantAdmin: null,
//    },
//    euroExchangeRate: null,
//  }
//}

const getDefaultState = () => {
  return {
    tenantinfo: {
      tenantname: null,
      title: null,
      details: {
        Id: null,
        CId: null
      },
      description: null,
      id: null,
      redirect: false,
      primarycolor: null,
      secondarycolor: null,
      baseAPI: null,
      fullURL: null,
      tenantLogoBase64: null,
      logo: null,
      paymentOption: null,
      userRoleCost: {
        Participant: null,
        Facilitator: null,
        TenantAdmin: null,
      },
      euroExchangeRate: null,
    }
  }
};

const state = getDefaultState();

const getters = {

  StateTenant: (state) => state.tenantinfo,

  GetStateTenantColors: (state) => {
    return {
      primaryColor: state.tenantinfo.primarycolor,
      secondaryColor: state.tenantinfo.secondarycolor,
    }
  },

  getPaymentOption: (state) => state.tenantinfo.paymentOption,

  getEuroExchangeRate: (state) => state.tenantinfo.euroExchangeRate,

}

const actions = {
  isIP(address) {
    const r = RegExp(
      '^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]).){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$'
    )
    return r.test(address)
  },

  // async createTenant({commit, state, rootState}, payload) {
  //
  //   axios.defaults.baseURL = state.tenantinfo.baseAPI
  //
  //   try {
  //
  //
  //     let config = {
  //       headers: {
  //         Authorization: rootState.auth.bearer
  //       }
  //     };
  //
  //     let payloadAPI = {
  //       "description": payload.description,
  //       "name": payload.name,
  //       "primarycolor": payload.primarycolor,
  //       "secondarycolor": payload.secondarycolor,
  //       "stage": payload.stage,
  //       "title": payload.title,
  //       "logo": payload.logo,
  //       "paymentOption": null,
  //       "userRoleCost": {
  //         Participant: null,
  //         Facilitator: null,
  //         TenantAdmin: null,
  //       },
  //       "euroExchangeRate": null,
  //     }
  //
  //     let response = await axios.post('/tenant', payloadAPI, config);
  //
  //   }
  //   catch (err) {
  //     throw new Error(err);
  //   }
  // },

  // async deleteTenant({ commit, state, rootState }) {
  //
  //   const ID = '';
  //
  //   let config = {
  //     headers: {
  //       Authorization: rootState.auth.bearer
  //     }
  //   }
  //
  //   try {
  //
  //     let response = await axios.delete(`/tenant/${ID}`, config);
  //     console.log(response.data)
  //   }
  //   catch (err) {
  //
  //   }
  // },

  async getTenant({ commit }) {
    // identify DEV server or deploy stages and return the Tenant name. for DEV server you get always "commovis" back
    var URLtenantname = commovisconfig.default_tenant
    var URLbaseAPI = commovisconfig.default_base_api
    var URLTenant = commovisconfig.dev_stage_URLbase_end
    if (actions.isIP(window.location.hostname)) {
      // DEV MACHINE
      console.log('this is a DEV mashine')

      URLTenant =
        'https://' + URLtenantname + commovisconfig.dev_stage_URLbase_end
      console.log(URLTenant)
    } else {
      // Deployed TO AWS
      //console.log('this is deployed')
      const URLstagename = window.location.host.split('.')[1]
        ? window.location.host.split('.')[1]
        : false

      if (URLstagename === 'dev') {
        // DEV STAGE
        //console.log('dev stage')
        URLtenantname = window.location.host.split('.')[1]
          ? window.location.host.split('.')[0]
          : false
        URLbaseAPI = commovisconfig.dev_stage_base_api
        URLTenant =
          'https://' + URLtenantname + commovisconfig.dev_stage_URLbase_end
      } else {
        // PROD STAGE
        //console.log('PROD stage')
        URLtenantname = window.location.host.split('.')[1]
          ? window.location.host.split('.')[0]
          : false
        URLbaseAPI = commovisconfig.prod_stage_base_api
        URLTenant =
          'https://' + URLtenantname + commovisconfig.prod_stage_URLbase_end
      }
    }
    axios.defaults.baseURL = URLbaseAPI
    commit('setTenantAPIurl', URLbaseAPI)
    commit('setfullTenantURL', URLTenant)

    try {
      let response = await axios.post('v2/tenantinfo/' + URLtenantname)
      //check if redirect tenant. if yes, redirect with replace
      if (response.data.redirect)
        location.replace(commovisconfig.redirect_tenant_url)

      commit('setTenant', response.data)
      commit('setTenantAPIurl', URLbaseAPI)
      commit('setfullTenantURL', URLTenant)
    } catch (err) {
      // tenant doesen't exist redirect with replace
      //console.log(err)

      alert(err)
      location.replace(commovisconfig.redirect_tenant_url)
    }
  },

  async update({ commit, state, rootState }, payload) {
    axios.defaults.baseURL = state.tenantinfo.baseAPI

    let config = {
      headers: {
        Authorization: rootState.auth.bearer
      }
    }

    // console.log('tenant id:', state.tenantinfo.id);
    // console.log('payload:', payload);
    // console.log('config:', config);

    try {

      payload.tenantname = state.tenantinfo.tenantname;

      let response = await axios.put(
        'v2/tenant/' + state.tenantinfo.id,
        payload,
        config
      )
      //console.log(response)
      //check if redirect tenant. if yes, redirect with replace

      commit('updateTenant', payload)
      commit('loading/setLoading', false, { root: true })
    } catch (err) {
      commit('loading/setLoading', false, { root: true })
    }
  },

  async setTenantLogo({ commit, dispatch, state, rootState }, payload) {
    axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI
    let config = {
      headers: {
        Authorization: rootState.auth.bearer
      }
    };

    const picture_old = state.tenantinfo.logo;

    let payloadAPI = {
      logo: payload.logo,
      tenantname: state.tenantinfo.tenantname,
    }

    try {
      let response = await axios.post('/v2/tenant/update-logo', payloadAPI, config)

      const updatedLogo = response.data.updatedLogo;

      commit('setTenantLogo', updatedLogo.logo);


      if (picture_old !== null) {
        const delete_payload = {
          folder_name: 'tenant_assets',
          file_name: picture_old
        }

        await dispatch('file/deleteFile', delete_payload, {root: true});
      }

      commit('loading/setLoading', false, {
        root: true
      });

      commit('file/setUploadVisible', false, {
        root: true
      });


    } catch (err) {
      alert(err)
    }
  },
  async getTenantLogoBase64({ commit, state }) {
    if (!state.tenantinfo.tenantLogoBase64) {
      const logoURL = await this.dispatch('file/getTenantLogoFile');
      const response = await fetch(logoURL);
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;
        commit('setTenantLogoBase64', base64data);
      };
      reader.readAsDataURL(blob);
    }
  }
  

}

const mutations = {
  setTenant(state, tenantinfo) {
    state.tenantinfo = tenantinfo
  },
  updateTenant(state, tenantinfo) {
    state.tenantinfo.title = tenantinfo.title
    state.tenantinfo.description = tenantinfo.description
    state.tenantinfo.primarycolor = tenantinfo.primarycolor
    state.tenantinfo.secondarycolor = tenantinfo.secondarycolor
  },
  setTenantAPIurl(state, baseAPI) {
    //console.log('setTenantAPIurl')
    //console.log(baseAPI)
    state.tenantinfo.baseAPI = baseAPI
  },
  setfullTenantURL(state, fullURL) {
    state.tenantinfo.fullURL = fullURL
  },
  setTenantLogo(state, logo) {
    state.tenantinfo.logo = logo;
  },
  setTenantLogoBase64(state, base64) {
    if (!state.tenantinfo.tenantLogoBase64) {
      state.tenantinfo.tenantLogoBase64 = base64;
    }
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
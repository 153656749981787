import axios from 'axios';

const state = {
  tenants: {
    tenants: []
  },
  journeys: [],
  users: [],
  error: null,
  isLoading: false
};

const mutations = {
  SET_TENANTS(state, tenants) {
    state.tenants = tenants || { tenants: [] };
  },
  SET_JOURNEYS(state, journeys) {
    state.journeys = journeys || [];
  },
  SET_USERS(state, users) {
    state.users = users || [];
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  }
};

const actions = {
  async loadTenants({ commit, rootState }) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    
    try {
      console.log('CMV Store: Starting to load tenants...');
      
      const baseAPI = rootState.tenant?.tenantinfo?.baseAPI;
      const authToken = rootState.auth?.bearer;
      
      if (!baseAPI || !authToken) {
        throw new Error('Missing required configuration (baseAPI or authentication token)');
      }
      
      console.log('CMV Store: Making API request to:', baseAPI);
      
      const response = await axios.get(`${baseAPI}/v2/cmv/overviewtenants`, {
        headers: {
          Authorization: authToken
        }
      });

      console.log('CMV Store: Received response:', response.data);
      
      if (!response.data || !Array.isArray(response.data.tenants)) {
        throw new Error('Invalid response format from server');
      }
      
      commit('SET_TENANTS', response.data);
      return response.data;
    } catch (error) {
      console.error('CMV Store: Error loading tenants:', error);
      commit('SET_ERROR', error.message || 'Failed to load tenants');
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async loadJourneys({ commit, rootState }, { tenantId }) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    
    try {
      const baseAPI = rootState.tenant?.tenantinfo?.baseAPI;
      const authToken = rootState.auth?.bearer;
      
      if (!baseAPI || !authToken || !tenantId) {
        throw new Error('Missing required parameters');
      }
      
      const response = await axios.get(`${baseAPI}/v2/cmv/tenantjourneys/${tenantId}`, {
        headers: {
          Authorization: authToken
        }
      });
      
      if (!response.data || !Array.isArray(response.data.journeys)) {
        throw new Error('Invalid response format from server');
      }
      
      commit('SET_JOURNEYS', response.data.journeys);
      return response.data;
    } catch (error) {
      console.error('CMV Store: Error loading journeys:', error);
      commit('SET_ERROR', error.message || 'Failed to load journeys');
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async loadUsers({ commit, rootState }, { tenantId }) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    
    try {
      const baseAPI = rootState.tenant?.tenantinfo?.baseAPI;
      const authToken = rootState.auth?.bearer;
      
      if (!baseAPI || !authToken || !tenantId) {
        throw new Error('Missing required parameters');
      }
      
      const response = await axios.get(`${baseAPI}/v2/cmv/tenantusers/${tenantId}`, {
        headers: {
          Authorization: authToken
        }
      });
      
      if (!response.data || !Array.isArray(response.data.users)) {
        throw new Error('Invalid response format from server');
      }
      
      commit('SET_USERS', response.data.users);
      return response.data;
    } catch (error) {
      console.error('CMV Store: Error loading users:', error);
      commit('SET_ERROR', error.message || 'Failed to load users');
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async generateStripeReport({ rootState }, { month }) {
    try {
      const baseAPI = rootState.tenant.tenantinfo.baseAPI;
      const authToken = rootState.auth.bearer;

      const response = await axios.get(`${baseAPI}/v2/cmv/stripe/report/${month}`, {
        headers: {
          Authorization: authToken
        },
        responseType: 'blob'
      });

      return response;
    } catch (error) {
      console.error('Error generating Stripe report:', error);
      throw error;
    }
  }
};

const getters = {
  getTenants: state => state.tenants.tenants,
  getJourneys: state => state.journeys,
  getUsers: state => state.users,
  getError: state => state.error,
  getTenantsWithRedirectFalse: state => state.tenants.tenants,
  getTenantsWithRedirectTrue: state => []
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};